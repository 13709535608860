import { Flex } from '@stars-ecom/shared-atoms-ui'
import { AccountLayout, AccountReturnForm, UserContext } from '@stars-ecom/shared-organisms-ui'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const AccountReturnFormPage = (props) => {
  const { location, pageContext } = props
  const currentUser = useContext(UserContext)
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      pb="15px"
      backgroundColor="#f6f3f1"
      w="100%">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`Mes retours produits - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}
        />
        <title>{`Mes retours produits - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <AccountLayout currentUser={currentUser}>
        <AccountReturnForm
          order={location?.state}
          returnDetails={pageContext?.returns?.settings}
          store={process.env.GATSBY_API_WEBSITE}
          urlWebsite={location?.origin}
        />
      </AccountLayout>
    </Flex>
  )
}

AccountReturnFormPage.pageType = 'account'

export default AccountReturnFormPage
